import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import generator from 'generate-password';

import closeIcon from '../../assets/images/close.svg';
import ModalContainer from '../ModalContainer';
import Input from '../../components/UI/Input';
import FilledButton from '../../components/UI/Buttons/FilledButton';
import classes from './styles.module.scss';

export default function CreateOrUpdateUserModal({
  show,
  handleClose,
  handleSubmit,
  selectedUser,
  type,
}) {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedUser) {
      setLogin(selectedUser.login);
    } else {
      setLogin('');
    }
  }, [selectedUser]);

  const handleGeneratePassword = useCallback(() => {
    const generatedPassword = generator.generate({
      numbers: true,
    });

    setPassword(generatedPassword);
  }, []);

  const resetForm = () => {
    setLogin('');
    setPassword('');
  };

  const title = type === 'create' ? 'Add new User' : 'Update password';
  const isSubmitDisabled = !login.length || !password.length;

  return (
    <ModalContainer
      show={show}
      handleClose={() => {
        handleClose();
        resetForm();
      }}
    >
      <div className={classes.CreateOrUpdateUserModal}>
        <button
          type="button"
          className={classes.closeIcon}
          onClick={() => {
            handleClose();
            resetForm();
          }}
        >
          <img src={closeIcon} alt="Close" />
        </button>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit({ login, password });
            handleClose();
            resetForm();
          }}
          autoComplete="off"
        >
          <h1>{t(title)}</h1>
          {type === 'create' ? (
            <Input
              inputType="default"
              value={login}
              type="text"
              placeholder="Login"
              onChange={(event) => setLogin(event.target.value)}
              autoComplete="off"
            />
          ) : (
            <span className={classes.login}>Login: {login}</span>
          )}

          <Input
            inputType="password"
            value={password}
            placeholder={t('Password')}
            onChange={(event) => setPassword(event.target.value)}
            autoComplete="new-password"
          />
          <p>
            {t('or')}{' '}
            <span onClick={handleGeneratePassword}>{t('generate')}</span>
          </p>
          <FilledButton isDisabled={isSubmitDisabled}>{t('Save')}</FilledButton>
        </form>
      </div>
    </ModalContainer>
  );
}
