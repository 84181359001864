export function adjustTimezoneOffset(fromValue, toValue) {
  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;

  const splittedFromValue = fromValue?.split(':');
  const splittedToValue = toValue?.split(':');

  const adjustedStartHour = Math.min(
    Math.max(+splittedFromValue[0] + offsetInHours, 0),
    23
  );
  const adjustedEndHour = Math.min(
    Math.max(+splittedToValue[0] + offsetInHours, 0),
    23
  );

  const adjustedFromValue = fromValue
    ? `${adjustedStartHour}:${splittedFromValue[1]}`
    : null;
  const adjustedToValue = toValue
    ? `${adjustedEndHour}:${splittedToValue[1]}`
    : null;

  return [adjustedFromValue, adjustedToValue];
}
