import React from 'react';

import classes from './styles.module.scss';

export default function FilledButton({ isDisabled, handleClick, children }) {
  return (
    <button
      disabled={isDisabled}
      type="submit"
      className={classes.FilledButton}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
