import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import exportIcon from '../../assets/images/export.svg';
import Select from './Select';
import TextInput from './TextInput';
import classes from './styles.module.scss';
import Calendar from './Calendar';
import TimeRange from './TimeRange';

export default function Filters({
  textFilters,
  selectFilters,
  calendarFilters,
  singleLine,
  applyFilters,
  timeRangeFilters,
  handleExport,
}) {
  const { t } = useTranslation();

  const resetFilters = () => {
    textFilters?.forEach((filter) => filter.setValue(''));
    selectFilters?.forEach((filter) => filter.setValue([]));
    calendarFilters?.forEach((filter) => filter.setValue(null));
    timeRangeFilters?.forEach((filter) => {
      filter.setFromValue(null);
      filter.setToValue(null);
    });

    applyFilters(true);
  };

  return (
    <div
      className={classNames(classes.Filters, {
        [classes.singleLine]: singleLine,
      })}
    >
      <div className={classNames(classes.row, classes.filtersRow)}>
        <div className={classes.filtersContainer}>
          {calendarFilters?.map((filter) => (
            <Calendar
              key={filter.placeholder}
              placeholder={filter.placeholder}
              value={filter.value}
              setValue={filter.setValue}
            />
          ))}

          {timeRangeFilters?.map((filter) => {
            return (
              <TimeRange
                key={filter.placeholderTo + filter.placeholderFrom}
                placeholderFrom={filter.placeholderFrom}
                placeholderTo={filter.placeholderTo}
                toValue={filter.toValue}
                fromValue={filter.fromValue}
                setToValue={filter.setToValue}
                setFromValue={filter.setFromValue}
              />
            );
          })}

          {textFilters?.map((filter) => (
            <TextInput
              key={filter.placeholder}
              placeholder={filter.placeholder}
              value={filter.value}
              setValue={filter.setValue}
            />
          ))}

          {selectFilters?.map((filter) => (
            <Select
              options={filter.options}
              key={filter.placeholder}
              placeholder={filter.placeholder}
              value={filter.value}
              setValue={filter.setValue}
              width={filter.width}
              singleOption={filter.singleOption}
            />
          ))}
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            className={classes.resetButton}
            onClick={resetFilters}
          >
            {t('Reset')}
          </button>
          <button
            type="button"
            className={classes.applyButton}
            onClick={applyFilters}
          >
            {t('Apply')}
          </button>
        </div>
      </div>
      <div className={classNames(classes.row, classes.exportRow)}>
        <button
          type="button"
          className={classes.exportButton}
          onClick={handleExport}
        >
          <img src={exportIcon} alt="Export" />
          {t('Export')}
        </button>
      </div>
    </div>
  );
}
