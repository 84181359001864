import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import nl from './nl.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'nl',
  interpolation: {
    escapeValue: false,
  },
  lng: localStorage.getItem('language') || 'nl',
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
});

export default i18n;
