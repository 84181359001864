import React, { useState, useEffect } from 'react';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import classNames from 'classnames';

import classes from './styles.module.scss';

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
const minutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
];

export default function TimeRange({
  placeholderFrom,
  placeholderTo,
  setToValue,
  setFromValue,
  toValue,
  fromValue,
}) {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [disabledHoursTo, setDisabledHoursTo] = useState([]);
  const [disabledMinutesTo, setDisabledMinutesTo] = useState([]);
  const [disabledHoursFrom, setDisabledHoursFrom] = useState([]);
  const [disabledMinutesFrom, setDisabledMinutesFrom] = useState([]);

  useEffect(() => {
    if (!fromValue?.length) {
      setFrom(null);
    }

    if (!toValue?.length) {
      setTo(null);
    }
  }, [fromValue, toValue]);

  useEffect(() => {
    const inputs = document.body.querySelectorAll(
      `.${classes.TimeRange} input`
    );

    setFromValue(inputs[0]?.value);
    setToValue(inputs[1]?.value);
  }, [to, from]);

  // Prevent usage of incorrect time ranges
  useEffect(() => {
    if (fromValue) {
      const fromHour = parseInt(fromValue.split(':')[0], 10);
      const fromMinute = parseInt(fromValue.split(':')[1], 10);

      setDisabledHoursTo(hours.slice(0, fromHour));

      if (toValue) {
        const toHour = parseInt(toValue.split(':')[0], 10);
        if (fromHour === toHour) {
          setDisabledMinutesTo(minutes.slice(0, fromMinute));
        } else {
          setDisabledMinutesTo([]);
        }
      }
    } else {
      setDisabledHoursTo([]);
      setDisabledMinutesTo([]);
    }

    if (toValue) {
      const toHour = parseInt(toValue.split(':')[0], 10);
      const toMinute = parseInt(toValue.split(':')[1], 10);

      setDisabledHoursFrom(hours.slice(toHour + 1));

      if (fromValue) {
        const fromHour = parseInt(fromValue.split(':')[0], 10);
        if (fromHour === toHour) {
          setDisabledMinutesFrom(minutes.slice(toMinute + 1));
        } else {
          setDisabledMinutesFrom([]);
        }
      }
    } else {
      setDisabledHoursFrom([]);
      setDisabledMinutesFrom([]);
    }
  }, [toValue, fromValue]);

  return (
    <div className={classes.TimeRange}>
      <TimePicker
        placeholder={placeholderFrom}
        showSecond={false}
        onChange={setFrom}
        value={from}
        format="H:mm"
        className={classNames(classes.input, classes.from)}
        inputReadOnly
        disabledHours={() => disabledHoursFrom}
        disabledMinutes={() => disabledMinutesFrom}
      />

      <TimePicker
        placeholder={placeholderTo}
        showSecond={false}
        onChange={setTo}
        value={to}
        format="H:mm"
        className={classNames(classes.input, classes.to)}
        inputReadOnly
        disabledHours={() => disabledHoursTo}
        disabledMinutes={() => disabledMinutesTo}
      />
    </div>
  );
}
