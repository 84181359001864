/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import userIcon from '../../assets/images/user.svg';
import sortIcon from '../../assets/images/sort.svg';
import { API_URL } from '../../constants/main';
import { formatDateByLanguage } from '../../helpers/formatDateByLanguage';
import Header from '../../components/Header';
import Filters from '../../components/Filters';
import Spinner from '../../components/UI/Spinner';
import ImageModal from '../../modals/ImageModal';
import classes from './styles.module.scss';
import { adjustTimezoneOffset } from '../../helpers/adjustTimezoneOffset';

const exportAsPdf = (absences, location) => {
  /* eslint-disable new-cap */
  const doc = new jsPDF();
  const head = [
    [
      'Date of last exit',
      'Time of last exit',
      'Name',
      'Last name',
      'Room number',
      'Nights of absence',
    ],
  ];
  const x = doc.internal.pageSize.width / 2;
  const formattedDate = moment().format('DD-MM-YYYY HH:mm');

  doc.setFontSize(24);
  doc.setTextColor('#007aff');

  doc.text(`ABSENCE \n ${location} ${formattedDate}`, x, 12, {
    align: 'center',
  });

  autoTable(doc, {
    startY: 28,
    headStyles: { fillColor: '#007aff' },
    head,
    body: absences,
    theme: 'grid',
    styles: {
      halign: 'center',
    },
  });

  doc.save(`absence-${location}-${formattedDate}.pdf`);
};

export default function AbsencePage({ match }) {
  const [absences, setAbsences] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [dateFilter, setDateFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');
  const [roomNumberFilter, setRoomNumberFilter] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [timeFromFilter, setTimeFromFilter] = useState(null);
  const [timeToFilter, setTimeToFilter] = useState(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const textFilters = [
    {
      placeholder: t('Name'),
      value: nameFilter,
      setValue: setNameFilter,
      name: 'name',
    },
    {
      placeholder: t('Last name'),
      value: lastNameFilter,
      setValue: setLastNameFilter,
      name: 'lastName',
    },
    {
      placeholder: t('Room number'),
      value: roomNumberFilter,
      setValue: setRoomNumberFilter,
      name: 'roomNumber',
    },
  ];

  const calendarFilters = [
    {
      placeholder: t('Date'),
      value: dateFilter,
      setValue: setDateFilter,
      name: 'date',
    },
  ];

  const timeRangeFilters = [
    {
      placeholderFrom: t('From'),
      placeholderTo: t('To'),
      name: 'time',
      fromValue: timeFromFilter,
      setFromValue: setTimeFromFilter,
      toValue: timeToFilter,
      setToValue: setTimeToFilter,
    },
  ];

  const applyFilters = (reset) => {
    const filters = {};

    if (reset === true) {
      setAppliedFilters(filters);
      return;
    }

    textFilters.forEach((filter) => {
      filters[filter.name] = filter.value;
    });

    calendarFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(filter.value);
    });

    timeRangeFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(
        adjustTimezoneOffset(filter.fromValue, filter.toValue)
      );
    });

    setAppliedFilters(filters);
  };

  const getAbsences = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${API_URL}/absences/NL/DWL`, {
        params: { ...appliedFilters },
      });

      setAbsences(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [appliedFilters]);

  useEffect(() => {
    getAbsences();
  }, [getAbsences]);

  const handleSortClick = () => {
    const newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    const sortedAbsences = absences.sort((a, b) => {
      const timeA = a.isEnter
        ? 0
        : Math.round(moment().diff(moment(a.createdAt), 'days', true));

      const timeB = b.isEnter
        ? 0
        : Math.round(moment().diff(moment(b.createdAt), 'days', true));

      return newSortOrder === 'ASC' ? timeA - timeB : timeB - timeA;
    });

    setSortOrder(newSortOrder);
    setAbsences(sortedAbsences);
  };

  const handleExport = () => {
    const formattedAbsences = absences.map((absence) => {
      const date = formatDateByLanguage(absence.createdAt);
      const time = moment(absence.createdAt).format('HH:mm');
      const name = absence.page.firstName;
      const lastName = absence.page.lastName;
      const roomNumber = absence.page.roomNumber;
      const nights = absence.isEnter
        ? '0'
        : Math.round(moment().diff(moment(absence.createdAt), 'days', true));

      return [date, time, name, lastName, roomNumber, nights];
    });

    if (absences.length) {
      exportAsPdf(formattedAbsences, match.params.location.toUpperCase());
    }
  };

  return (
    <div className={classes.AbsencePage}>
      <Header className={classes.header} logoClassName={classes.logo}>
        <div className={classes.pageTitle}>{t('Absence')}</div>
      </Header>
      <Filters
        textFilters={textFilters}
        calendarFilters={calendarFilters}
        timeRangeFilters={timeRangeFilters}
        applyFilters={applyFilters}
        handleExport={handleExport}
      />

      <div className={classes.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>{t('Date of last exit')}</th>
              <th>{t('Time of last exit')}</th>
              <th>{t('Photo')}</th>
              <th>{t('Name')}</th>
              <th>{t('Last name')}</th>
              <th>{t('Room number')}</th>
              <th>{t('Nights of absence')}</th>
              <th>
                <button
                  type="button"
                  className={classes.sortButton}
                  onClick={handleSortClick}
                >
                  <img src={sortIcon} alt="Sort" />
                </button>
              </th>
            </tr>
          </thead>
          {isLoading ? (
            <div className={classes.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <tbody>
              {absences?.map((absence) => (
                <tr key={absence.id}>
                  <td>{formatDateByLanguage(absence.createdAt)}</td>
                  <td>{moment(absence.createdAt).format('HH:mm')}</td>
                  <td className={classes.avatar}>
                    <img
                      src={absence.page.avatar || userIcon}
                      alt="User"
                      onClick={() => {
                        setSelectedPhoto(absence.page.avatar || userIcon);
                        setIsImageModalVisible(true);
                      }}
                    />
                  </td>
                  <td>{absence.page.firstName}</td>
                  <td>{absence.page.lastName}</td>
                  <td>{absence.page.roomNumber}</td>
                  <td className={classes.absence}>
                    {absence.isEnter
                      ? '0'
                      : Math.round(
                          moment().diff(moment(absence.createdAt), 'days', true)
                        )}
                  </td>
                  <td />
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <ImageModal
        show={isImageModalVisible}
        handleClose={() => setIsImageModalVisible(false)}
        image={selectedPhoto}
      />
    </div>
  );
}
