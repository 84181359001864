import React from 'react';

import classes from './styles.module.scss';

export default function TextInput({ placeholder, value, setValue }) {
  return (
    <input
      type="text"
      className={classes.TextInput}
      placeholder={placeholder}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
}
