/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import classNames from 'classnames';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import './calendar.scss';
import classes from './styles.module.scss';

export default function CalendarModal({
  activeRange,
  setActiveRange,
  selectRange,
  value,
  setValue,
  hideModal,
}) {
  const { t } = useTranslation();

  const calendarRef = useRef();
  const previousDate = useRef();

  const resetDate = () => {
    setActiveRange('');
    setValue(previousDate.current);
  };

  useEffect(() => {
    previousDate.current = value;

    return () => {
      previousDate.current = value;
    };
  }, []);

  useOnClickOutside(calendarRef, () => {
    hideModal();
    resetDate();
  });

  return (
    <div className={classes.Modal}>
      <div className={classes.content} ref={calendarRef}>
        <div className={classes.row}>
          <div className={classes.ranges}>
            <ul>
              <li
                className={classNames({
                  [classes.selected]: activeRange === 'This week',
                })}
                onClick={() => selectRange('This week')}
              >
                <span>{t('This week')}</span>
              </li>
              <li
                onClick={() => selectRange('Last week')}
                className={classNames({
                  [classes.selected]: activeRange === 'Last week',
                })}
              >
                <span>{t('Last week')}</span>
              </li>
              <li
                onClick={() => selectRange('This month')}
                className={classNames({
                  [classes.selected]: activeRange === 'This month',
                })}
              >
                <span>{t('This month')}</span>
              </li>
              <li
                onClick={() => selectRange('This year')}
                className={classNames({
                  [classes.selected]: activeRange === 'This year',
                })}
              >
                <span>{t('This year')}</span>
              </li>
              <li
                onClick={() => selectRange('Last year')}
                className={classNames({
                  [classes.selected]: activeRange === 'Last year',
                })}
              >
                <span>{t('Last year')}</span>
              </li>
              <li
                onClick={() => selectRange('All time')}
                className={classNames({
                  [classes.selected]: activeRange === 'All time',
                })}
              >
                <span>{t('All time')}</span>
              </li>
            </ul>
          </div>
          <div className={classes.calenderContainer}>
            <div className={classes.calendar}>
              <Calendar
                selectRange
                value={value}
                onChange={(dates) => {
                  if (activeRange.length) {
                    setActiveRange('');
                  }

                  setValue(dates);
                }}
                className="calendarComponent"
                formatShortWeekday={(locale, date) =>
                  t(date.toLocaleString(locale, { weekday: 'narrow' }))
                }
                formatMonthYear={(_locale, date) =>
                  [
                    t('January'),
                    t('February'),
                    t('March'),
                    t('April'),
                    t('May'),
                    t('June'),
                    t('July'),
                    t('August'),
                    t('September'),
                    t('October'),
                    t('November'),
                    t('December'),
                  ][date.getMonth()]
                }
              />
            </div>
            <div className={classes.buttonsContainer}>
              <button
                type="button"
                onClick={() => {
                  hideModal();
                  resetDate();
                }}
                className={classes.cancelButton}
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className={classes.applyButton}
                disabled={!value?.length}
                onClick={() => {
                  hideModal();
                  previousDate.current = value;
                }}
              >
                {t('Apply')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
