/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import userIcon from '../../assets/images/user.svg';
import { formatDateByLanguage } from '../../helpers/formatDateByLanguage';
import Spinner from '../UI/Spinner';
import classes from './styles.module.scss';

const LogsTable = ({
  headers,
  data,
  lastListElementRef,
  onUserAvatarClick,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <table className={classes.checkIns}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th>{t(header)}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {!!data &&
            data.map((item, index) => {
              return (
                <tr
                  key={item.id}
                  ref={index === data.length - 1 ? lastListElementRef : null}
                >
                  <td className={classes.date}>
                    {formatDateByLanguage(item.createdAt)}
                  </td>

                  <td className={classes.date}>
                    {moment(item.createdAt).format('HH:mm')}
                  </td>

                  {headers.includes('Photo') && (
                    <td className={classes.avatar}>
                      <img
                        src={item.page.avatar || userIcon}
                        alt="User"
                        onClick={() =>
                          onUserAvatarClick(item.page.avatar || userIcon)
                        }
                      />
                    </td>
                  )}

                  <td>{!!item.page && item.page.firstName}</td>
                  <td>{!!item.page && item.page.lastName}</td>

                  {headers.includes('Room number') && (
                    <td>{!!item.page && item.page.roomNumber}</td>
                  )}

                  <td>
                    {!!item.page &&
                      !!item.page.birthday &&
                      moment().diff(
                        moment(item.page.birthday, 'YYYY-MM-DD'),
                        'years'
                      )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {isLoading && (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default LogsTable;
