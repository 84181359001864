export const ROLES = {
  GATE_KEEPER: 'gateKeeper',
  STATISTICS_VIEWER: 'statisticsViewer',
  USER_ADMIN: 'userAdmin',
};

const LOCAL_STORAGE_KEY = {
  TOKEN: 'access_token',
  EXPIRY_DATE: 'expires_in',
  ROLE: 'role',
};

const ACCESS_TOKEN_DATA_REGEXP = /^[^.]+\.([^\s]+)[^.]*$/i;

export const saveAuthData = ({ accessToken, expiresIn, role }) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, accessToken.toString());
  localStorage.setItem(LOCAL_STORAGE_KEY.EXPIRY_DATE, expiresIn.toString());
  localStorage.setItem(LOCAL_STORAGE_KEY.ROLE, role);
};

export const getAuthData = () => {
  return {
    accessToken: localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) || '{}',
    role: localStorage.getItem(LOCAL_STORAGE_KEY.ROLE) || '{}',
  };
};

export const hasAuthData = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);
  const expired = Number(localStorage.getItem(LOCAL_STORAGE_KEY.EXPIRY_DATE));

  return token && expired > Date.now();
};

export const clearAuthData = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY.EXPIRY_DATE);
  localStorage.removeItem(LOCAL_STORAGE_KEY.ROLE);
};

/**
 * Get data saved in token (userId, role..)
 * @returns {object|null}
 */
export const getTokenData = () => {
  const { accessToken } = getAuthData();

  if (!accessToken || !ACCESS_TOKEN_DATA_REGEXP.test(accessToken)) {
    return null;
  }

  const accessTokenPayload = accessToken.split('.')[1];

  return JSON.parse(window.atob(accessTokenPayload));
};
