import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ROLES } from '../helpers/authStorage';
import PrivateRoute from '../containers/PrivateRoute';
import CheckInPage from '../pages/CheckInPage';
import LogsPage from '../pages/LogsPage';
import DeclinesPage from '../pages/DeclinesPage';
import SecurityLoginPage from '../pages/SecurityLoginPage';
import ManagerLoginPage from '../pages/ManagerLoginPage';
import AdminLoginPage from '../pages/AdminLoginPage';
import AdminPanelPage from '../pages/AdminPanelPage';
import NotFoundPage from '../pages/NotFoundPage';
import SecurityPage from '../pages/SecurityPage';
import AbsencePage from '../pages/AbsencePage';
import VisitorsPage from '../pages/VisitorsPage';
import MapPage from '../pages/MapPage';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* <PrivateRoute path="/dashboard" component={DashboardRoutes} /> */}
      <Route exact path="/gates-login" component={SecurityLoginPage} />
      <Route exact path="/manager-login" component={ManagerLoginPage} />
      <Route exact path="/admin-login" component={AdminLoginPage} />
      {/* <Route exact path="/forgot-password" component={ForgotPasswordPage} /> */}
      {/* <Route exact path="/reset-password" component={ResetPasswordPage} /> */}
      {/* <Route exact path="/error" component={ErrorPage} /> */}

      <PrivateRoute
        path="/admin-panel"
        component={AdminPanelPage}
        role={ROLES.USER_ADMIN}
        redirectPath="/admin-login"
      />
      <PrivateRoute
        path="/:country/:location/logs"
        component={LogsPage}
        role={ROLES.STATISTICS_VIEWER}
        redirectPath="/manager-login"
      />
      <PrivateRoute
        path="/:country/:location/visitors"
        component={VisitorsPage}
        role={ROLES.STATISTICS_VIEWER}
        redirectPath="/manager-login"
      />
      <PrivateRoute
        path="/:country/:location/declines"
        component={DeclinesPage}
        role={ROLES.STATISTICS_VIEWER}
        redirectPath="/manager-login"
      />
      <PrivateRoute
        path="/:country/:location/absence"
        component={AbsencePage}
        role={ROLES.STATISTICS_VIEWER}
        redirectPath="/manager-login"
      />
      <PrivateRoute
        path="/:country/:location/security"
        component={SecurityPage}
        role={ROLES.STATISTICS_VIEWER}
        redirectPath="/manager-login"
      />
      <PrivateRoute
        path="/:country/:location/map"
        component={MapPage}
        role={ROLES.STATISTICS_VIEWER}
        redirectPath="/manager-login"
      />
      <PrivateRoute
        path="/:country/:location"
        component={CheckInPage}
        role={ROLES.GATE_KEEPER}
        redirectPath="/gates-login"
      />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
