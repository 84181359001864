/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Input({
  inputType,
  value,
  errors,
  isTouched,
  label,
  ...props
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { t } = useTranslation();

  let input;

  switch (inputType) {
    case 'default':
      input = (
        <>
          <span>{t(label)}</span>
          <input
            className={classNames({ [classes.active]: value.length })}
            value={value}
            {...props}
          />
          {errors && isTouched && (
            <span className={classes.error}>{t(errors)}</span>
          )}
        </>
      );
      break;

    case 'password':
      input = (
        <>
          <span>{t(label)}</span>
          <div className={classes.passwordContainer}>
            <input
              className={classNames({ [classes.active]: value.length })}
              type={isPasswordVisible ? 'text' : 'password'}
              value={value}
              {...props}
            />
            <div
              className={classes.eyeIcon}
              onClick={() => setIsPasswordVisible((prev) => !prev)}
            >
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                <path
                  d="M11 15C6.28287 15 2.24223 12.1091 0.539284 8C2.24223 3.89095 6.28287 1 11 1C15.7171 1 19.7578 3.89095 21.4607 8C19.7578 12.1091 15.7171 15 11 15ZM5.5 8C5.5 11.0361 7.96386 13.5 11 13.5C14.0361 13.5 16.5 11.0361 16.5 8C16.5 4.96386 14.0361 2.5 11 2.5C7.96386 2.5 5.5 4.96386 5.5 8ZM8.5 8C8.5 6.61614 9.61614 5.5 11 5.5C12.3839 5.5 13.5 6.61614 13.5 8C13.5 9.38386 12.3839 10.5 11 10.5C9.61614 10.5 8.5 9.38386 8.5 8Z"
                  fill={isPasswordVisible ? '#333333' : '#e3e4ea'}
                  stroke={isPasswordVisible ? '#333333' : '#e3e4ea'}
                />
              </svg>
            </div>
          </div>
          {errors && isTouched && (
            <span className={classes.error}>{t(errors)}</span>
          )}
        </>
      );
      break;

    default:
      break;
  }

  return <label className={classes.Input}>{input}</label>;
}
