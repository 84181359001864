/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';

import { actions } from '../../resources/users';
import logo from '../../assets/images/logo.png';
import { ROLES } from '../../helpers/authStorage';
import Toast from '../../components/UI/Toast';
import Input from '../../components/UI/Input';
import FilledButton from '../../components/UI/Buttons/FilledButton';
import classes from './styles.module.scss';

const VIEWS = {
  LOGIN: 'login',
  TWO_FACTOR_AUTHENTICATION: '2FA',
};

const loginValidationSchema = yup.object({
  login: yup.string().trim().required('Login is required'),
  password: yup.string().required('Password is required'),
});

const verificationValidationSchema = yup.object({
  verificationCode: yup
    .string()
    .matches(/^\d{4}$/, 'Code must be exactly 4 digits')
    .required('Verification code is required'),
});

export default function AdminLoginPage() {
  const [currentView, setCurrentView] = useState(VIEWS.LOGIN);
  const [email, setEmail] = useState('');
  const [isNotifyVisible, setIsNotifyVisible] = useState(false);
  const [hasVerificationCodeReset, setHasVerificationCodeReset] =
    useState(false);
  const [isCodeInputFocused, setIsCodeInputFocused] = useState(false);

  const codeInputRef = useRef(null);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const login = async (values) => {
    try {
      const response = await dispatch(
        actions.loginUser({
          login: values.login,
          password: values.password,
          role: ROLES.USER_ADMIN,
        })
      );

      setEmail(response.body.email);
      setCurrentView(VIEWS.TWO_FACTOR_AUTHENTICATION);
    } catch (error) {
      setIsNotifyVisible(true);
      setTimeout(() => setIsNotifyVisible(false), 5000);
    }
  };

  const loginFormik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: login,
  });

  const verify = async (values) => {
    try {
      await dispatch(
        actions.verifyUser({
          login: loginFormik.values.login,
          verificationCode: values.verificationCode,
        })
      );

      history.push('/admin-panel');
    } catch (error) {
      setIsNotifyVisible(true);
      setTimeout(() => {
        setIsNotifyVisible(false);
      }, 5000);
    }
  };

  const verificationFormik = useFormik({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: verificationValidationSchema,
    onSubmit: verify,
  });

  const resetVerificationCode = () => {
    login(loginFormik.values);
    setHasVerificationCodeReset(true);
  };

  const isLoginSubmitDisabled = useMemo(
    () =>
      !loginFormik.values.login.length || !loginFormik.values.password.length,
    [loginFormik.values.login, loginFormik.values.password]
  );

  return (
    <div className={classes.AdminLoginPage}>
      {currentView === VIEWS.LOGIN && (
        <>
          <img className={classes.uqLogo} src={logo} alt="UQ" />
          <form onSubmit={loginFormik.handleSubmit}>
            <h1>{t("Welcome to UQ Admin User's dashboard")} DWL</h1>
            <Input
              inputType="default"
              value={loginFormik.values.login}
              errors={loginFormik.errors.login}
              isTouched={loginFormik.touched.login}
              type="text"
              name="login"
              placeholder="Login"
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
            />

            <Input
              inputType="password"
              value={loginFormik.values.password}
              errors={loginFormik.errors.password}
              isTouched={loginFormik.touched.password}
              name="password"
              placeholder={t('Password')}
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
            />
            <FilledButton isDisabled={isLoginSubmitDisabled}>
              {t('Next')}
            </FilledButton>
          </form>
        </>
      )}

      {currentView === VIEWS.TWO_FACTOR_AUTHENTICATION && (
        <>
          <img className={classes.uqLogo} src={logo} alt="UQ" />
          <form
            className={classes.verificationForm}
            onSubmit={verificationFormik.handleSubmit}
          >
            <h1>
              {t('The code was sent to')} {email}
            </h1>
            <div className={classes.inputsContainer}>
              {[...Array(4)].map((_, index) => (
                <input
                  type="tel"
                  className={classNames({
                    [classes.active]:
                      isCodeInputFocused &&
                      verificationFormik.values.verificationCode.length ===
                        index,
                  })}
                  value={
                    verificationFormik.values.verificationCode[index] || ''
                  }
                  onClick={() => {
                    codeInputRef.current.focus();
                    setIsCodeInputFocused(true);
                  }}
                />
              ))}
            </div>

            <input
              className={classes.codeInput}
              ref={codeInputRef}
              type="tel"
              name="verificationCode"
              placeholder="Verification code"
              value={verificationFormik.values.verificationCode}
              onChange={verificationFormik.handleChange}
              onBlur={verificationFormik.handleBlur}
              maxLength={4}
            />
            <FilledButton
              isDisabled={!verificationFormik.values.verificationCode.length}
            >
              Login
            </FilledButton>
            <div className={classes.reset}>
              {hasVerificationCodeReset ? (
                <p>
                  Verification code sent to your email, don&apos;t forget to
                  check your spam
                </p>
              ) : (
                <p>
                  {t("Didn't get it")}?{' '}
                  <span onClick={resetVerificationCode}>{t('Resend')}</span>
                </p>
              )}
            </div>
          </form>
        </>
      )}

      <Toast show={isNotifyVisible}>
        {t('Something went wrong')}
        <br />
        {t('Please contact with admin')} +31638618185
      </Toast>
    </div>
  );
}
