import React from 'react';

import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ModalContainer({ show, handleClose, children }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      {children}
    </Modal>
  );
}
