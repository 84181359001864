/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { formatDateByLanguage } from '../../helpers/formatDateByLanguage';
import { API_URL } from '../../constants/main';
import Spinner from '../../components/UI/Spinner';
import Header from '../../components/Header';
import Filters from '../../components/Filters';
import classes from './styles.module.scss';
import { adjustTimezoneOffset } from '../../helpers/adjustTimezoneOffset';

const exportAsPdf = (securityLogs, location) => {
  /* eslint-disable new-cap */
  const doc = new jsPDF();
  const head = [['Date', 'Time', 'Login', 'Action']];
  const x = doc.internal.pageSize.width / 2;
  const formattedDate = moment().format('DD-MM-YYYY HH:mm');

  doc.setFontSize(24);
  doc.setTextColor('#007aff');

  doc.text(`SECURITY \n ${location} ${formattedDate}`, x, 12, {
    align: 'center',
  });

  autoTable(doc, {
    startY: 28,
    headStyles: { fillColor: '#007aff' },
    head,
    body: securityLogs,
    theme: 'grid',
    styles: {
      halign: 'center',
    },
  });

  doc.save(`security-${location}-${formattedDate}.pdf`);
};

export default function SecurityPage({ match }) {
  const [securityLogs, setSecurityLogs] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [actionFilter, setActionFilter] = useState([]);
  const [timeFromFilter, setTimeFromFilter] = useState(null);
  const [timeToFilter, setTimeToFilter] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const textFilters = [
    {
      placeholder: 'Login',
      value: nameFilter,
      setValue: setNameFilter,
      name: 'login',
    },
  ];

  const selectFilters = [
    {
      singleOption: true,
      width: 112,
      placeholder: t('Action'),
      value: actionFilter,
      setValue: setActionFilter,
      name: 'action',
      options: [
        { label: 'log IN', value: 'log IN' },
        { label: 'log OUT', value: 'log OUT' },
      ],
    },
  ];

  const calendarFilters = [
    {
      placeholder: t('Date'),
      value: dateFilter,
      setValue: setDateFilter,
      name: 'date',
    },
  ];

  const timeRangeFilters = [
    {
      placeholderFrom: t('From'),
      placeholderTo: t('To'),
      name: 'time',
      fromValue: timeFromFilter,
      setFromValue: setTimeFromFilter,
      toValue: timeToFilter,
      setToValue: setTimeToFilter,
    },
  ];

  const applyFilters = (reset) => {
    const filters = {};

    if (reset === true) {
      setAppliedFilters(filters);
      return;
    }

    textFilters.forEach((filter) => {
      filters[filter.name] = filter.value;
    });

    selectFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(
        filter.value.map((val) => val.value)
      );
    });

    calendarFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(filter.value);
    });

    timeRangeFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(
        adjustTimezoneOffset(filter.fromValue, filter.toValue)
      );
    });

    setAppliedFilters(filters);
  };

  const getSecurityLogs = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${API_URL}/securityLogs`, {
        params: {
          ...appliedFilters,
        },
      });

      setSecurityLogs(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [appliedFilters]);

  useEffect(() => {
    getSecurityLogs();
  }, [getSecurityLogs]);

  const handleExport = () => {
    const formattedSecurityLogs = securityLogs.map((log) => {
      const date = formatDateByLanguage(log.createdAt);
      const time = moment(log.createdAt).format('HH:mm');
      const login = log.visitorsCounterAdmin.login;
      const action = log.action;

      return [date, time, login, action];
    });

    if (securityLogs.length) {
      exportAsPdf(formattedSecurityLogs, match.params.location.toUpperCase());
    }
  };

  return (
    <div className={classes.SecurityPage}>
      <Header className={classes.header} logoClassName={classes.logo}>
        <div className={classes.pageTitle}>Security</div>
      </Header>
      <Filters
        textFilters={textFilters}
        selectFilters={selectFilters}
        calendarFilters={calendarFilters}
        timeRangeFilters={timeRangeFilters}
        applyFilters={applyFilters}
        handleExport={handleExport}
      />

      <div className={classes.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>{t('Date')}</th>
              <th>{t('Time')}</th>
              <th>Login</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          {isLoading ? (
            <div className={classes.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <tbody>
              {securityLogs?.map((securityLog) => (
                <tr key={securityLog.id}>
                  <td>{formatDateByLanguage(securityLog.createdAt)}</td>
                  <td>{moment(securityLog.createdAt).format('HH:mm')}</td>
                  <td>{securityLog.visitorsCounterAdmin.login}</td>
                  <td className={classes.action}>{securityLog.action}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
