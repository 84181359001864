/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import languageIcon from '../../assets/images/language.svg';
import logoutIcon from '../../assets/images/logout.svg';
import menuIcon from '../../assets/images/menu.svg';
import logo from '../../assets/images/logo.png';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ROLES, getAuthData, getTokenData } from '../../helpers/authStorage';
import { actions } from '../../resources/users';
import Drawer from '../Drawer';
import classes from './styles.module.scss';

const routesWithMenuButton = [
  'logs',
  'visitors',
  'declines',
  'absence',
  'security',
  'map',
];

const Header = ({ children, className, logoClassName }) => {
  const [isLanguagePickerVisible, setIsLanguagePickerVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const { i18n } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const languagePickerRef = useRef();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang, () => {
      localStorage.setItem('language', lang);
    });

    setIsLanguagePickerVisible(false);
  };

  const handleLogout = () => {
    const { role } = getAuthData();
    const { email } = getTokenData();

    dispatch(actions.logoutUser({ login: email }));

    switch (role) {
      case ROLES.GATE_KEEPER:
        history.push('/gates-login');
        break;

      case ROLES.STATISTICS_VIEWER:
        history.push('/manager-login');
        break;

      case ROLES.USER_ADMIN:
        history.push('/admin-login');
        break;

      default:
        break;
    }
  };

  const endpoint = pathname.split('/').pop();

  useOnClickOutside(languagePickerRef, () => setIsLanguagePickerVisible(false));

  return (
    <div className={classNames([classes.Header, className])}>
      <img
        className={classNames([classes.logo, logoClassName])}
        src={logo}
        alt="UQ"
      />
      <div className={classes.children}>{children}</div>
      <div className={classes.rightBlock}>
        <div className={classes.languagePicker} ref={languagePickerRef}>
          <button
            type="button"
            onClick={() => setIsLanguagePickerVisible((prev) => !prev)}
          >
            <img src={languageIcon} alt="Language" />
          </button>

          {isLanguagePickerVisible && (
            <ul>
              {['en', 'nl'].map((lang) => (
                <li key={lang} onClick={() => handleChangeLanguage(lang)}>
                  {lang}
                </li>
              ))}
            </ul>
          )}
        </div>

        {routesWithMenuButton.includes(endpoint) ? (
          <button
            type="button"
            className={classes.menuButton}
            onClick={() => setIsDrawerVisible((prev) => !prev)}
          >
            <img src={menuIcon} alt="Menu" />
          </button>
        ) : (
          <button type="button" onClick={handleLogout}>
            <img src={logoutIcon} alt="Logout" />
          </button>
        )}
      </div>

      <Drawer
        show={isDrawerVisible}
        hideDrawer={() => setIsDrawerVisible(false)}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default Header;
