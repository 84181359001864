import React from 'react';

import ModalContainer from '../ModalContainer';
import classes from './styles.module.scss';

export default function ImageModal({ show, handleClose, image }) {
  return (
    <ModalContainer show={show} handleClose={handleClose}>
      <div className={classes.ImageModal}>
        <img src={image} alt="" />
      </div>
    </ModalContainer>
  );
}
