/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useState, useRef } from 'react';

import classNames from 'classnames';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Select({
  options,
  placeholder,
  value,
  setValue,
  width,
  singleOption,
}) {
  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  const selectRef = useRef();

  const toggleOption = (option) => {
    setValue((prevOptions) => {
      if (prevOptions.some((prevOption) => prevOption.label === option.label)) {
        return prevOptions.filter(
          (prevOption) => prevOption.label !== option.label
        );
      }

      // Prevent selecting multipe options
      if (singleOption && prevOptions.length > 0) {
        return prevOptions;
      }

      return [...prevOptions, option];
    });
  };

  useOnClickOutside(selectRef, () => setIsOptionsListVisible(false));

  return (
    <div className={classes.Select} ref={selectRef} style={{ maxWidth: width }}>
      <div
        className={classes.selector}
        onClick={() => setIsOptionsListVisible((prevState) => !prevState)}
      >
        {value.label || placeholder}
      </div>
      {isOptionsListVisible && (
        <div className={classes.listContainer}>
          <ul>
            {options &&
              options.map((option) => (
                <li
                  key={option.label}
                  onClick={() => toggleOption(option)}
                  className={classNames({
                    [classes.selected]: value.some(
                      (opt) => opt.label === option.label
                    ),
                  })}
                >
                  <div className={classes.checkbox} />
                  <span>{option.label}</span>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}
