/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import deleteIcon from '../../assets/images/delete.svg';
import { API_URL } from '../../constants/main';
import { formatDateByLanguage } from '../../helpers/formatDateByLanguage';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import Filters from '../../components/Filters';
import Header from '../../components/Header';
import ConfirmationCheckbox from './ConfirmationCheckbox';
import Spinner from '../../components/UI/Spinner';
import classes from './styles.module.scss';

const exportAsPdf = (declines, location) => {
  /* eslint-disable new-cap */
  const doc = new jsPDF();
  const head = [['Date', 'Notes', 'Solved']];
  const x = doc.internal.pageSize.width / 2;
  const formattedDate = moment().format('DD-MM-YYYY HH:mm');

  doc.setFontSize(24);
  doc.setTextColor('#007aff');

  doc.text(`NON-APPROVED \n ${location} ${formattedDate}`, x, 12, {
    align: 'center',
  });

  autoTable(doc, {
    startY: 28,
    headStyles: { fillColor: '#007aff' },
    head,
    body: declines,
    theme: 'grid',
    styles: {
      halign: 'center',
    },
  });

  doc.save(`declines-${location}-${formattedDate}.pdf`);
};

export default function DeclinesPage({ match }) {
  const [dateFilter, setDateFilter] = useState(null);
  const [confirmedFilter, setConfirmedFilter] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [declines, setDeclines] = useState([]);
  const [selectedDeclineId, setSelectedDeclineId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { t } = useTranslation();

  const calendarFilters = [
    {
      placeholder: t('Date'),
      name: 'date',
      value: dateFilter,
      setValue: setDateFilter,
    },
  ];

  const selectFilters = [
    {
      singleOption: true,
      width: 160,
      placeholder: t('Solved'),
      name: 'isResolved',
      value: confirmedFilter,
      setValue: setConfirmedFilter,
      options: [
        { label: t('solved'), value: true },
        { label: t('not solved'), value: false },
      ],
    },
  ];

  const applyFilters = (reset) => {
    const filters = {};

    if (reset === true) {
      setAppliedFilters(filters);
      return;
    }

    selectFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(
        filter.value.map((val) => val.value)
      );
    });

    calendarFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(filter.value);
    });

    setAppliedFilters(filters);
  };

  const getDeclines = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${API_URL}/declines`, {
        params: { ...appliedFilters },
      });

      setDeclines(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [appliedFilters]);

  const deleteDecline = useCallback(async (declineId) => {
    try {
      await axios.delete(`${API_URL}/declines/${declineId}`);

      setDeclines((prevDeclines) =>
        prevDeclines.filter((decline) => decline.id !== declineId)
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDeclines();
  }, [getDeclines]);

  const handleDeleteDecline = (declineId) => {
    setIsDeleteConfirmationModalVisible(true);
    setSelectedDeclineId(declineId);
  };

  const handleExport = () => {
    const formattedDeclines = declines.map((decline) => {
      const date = formatDateByLanguage(decline.createdAt);
      const notes = decline.note;
      const confirmed = decline.isResolved ? 'solved' : 'not solved';

      return [date, notes, confirmed];
    });

    if (declines.length) {
      exportAsPdf(formattedDeclines, match.params.location.toUpperCase());
    }
  };

  return (
    <div className={classes.DeclinesPage}>
      <Header className={classes.header} logoClassName={classes.logo}>
        <div className={classes.pageTitle}>{t('Declines')}</div>
      </Header>
      <Filters
        calendarFilters={calendarFilters}
        selectFilters={selectFilters}
        singleLine
        applyFilters={applyFilters}
        handleExport={handleExport}
      />
      <div className={classes.tableContainer}>
        {isLoading ? (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>{t('Date')}</th>
                <th>{t('Name')}</th>
                <th>{t('Notes')}</th>
                <th>{t('Solved')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {declines.map((decline) => (
                <tr key={decline.id}>
                  <td className={classes.date}>
                    {formatDateByLanguage(decline.createdAt)}
                  </td>
                  <td>{decline.visitorsCounterAdmin?.login}</td>
                  <td className={classes.note}>{decline.note}</td>
                  <td>
                    <ConfirmationCheckbox
                      isConfirmed={decline.isResolved}
                      setDeclines={setDeclines}
                      declineId={decline.id}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleDeleteDecline(decline.id)}
                    >
                      <img src={deleteIcon} alt="Delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className={classes.counter}>
        <span>
          {t('Declines')}: {declines.length}
        </span>
      </div>

      <DeleteConfirmationModal
        show={isDeleteConfirmationModalVisible}
        handleClose={() => setIsDeleteConfirmationModalVisible(false)}
        handleConfirm={() => deleteDecline(selectedDeclineId)}
      />
    </div>
  );
}
