import moment from 'moment';
import 'moment/locale/nl';

export function formatDateByLanguage(date) {
  const currentLanguage = localStorage.getItem('language');
  const formattedDate = moment(date)
    .locale(currentLanguage)
    .format('ddd, DD-MM-YYYY')
    .replace('.', '');

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}
