import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Toast({ show, children }) {
  return (
    <div className={classNames(classes.Toast, { [classes.active]: show })}>
      {children}
    </div>
  );
}
