/* eslint-disable no-nested-ternary */
// export const NODE_ENV = process.env.NODE_ENV;
// export const API_URL = process.env.REACT_APP_API_URL;
// export const LINK_URL = process.env.REACT_APP_LINK_URL;

export const API_URL = /localhost/.test(window.location.hostname)
  ? process.env.REACT_APP_API_URL
  : /devmaxup/.test(window.location.hostname)
  ? process.env.REACT_APP_TEST_API_URL
  : 'https://scanid.help/api';

export const LINK_URL = /localhost/.test(window.location.hostname)
  ? process.env.REACT_APP_LINK_URL
  : /devmaxup/.test(window.location.hostname)
  ? process.env.REACT_APP_TEST_LINK_URL
  : 'https://scanid.help';
