import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import classes from './styles.module.scss';

const changeEndpoint = (pathname, endpoint) =>
  pathname.replace(/\/[^\\/]*$/, endpoint);

export default function Drawer({ show, hideDrawer, handleLogout }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const options = useMemo(
    () => [
      { to: changeEndpoint(pathname, '/logs'), label: 'Entrance/Exit' },
      { to: changeEndpoint(pathname, '/visitors'), label: 'Visitors' },
      { to: changeEndpoint(pathname, '/declines'), label: 'Declines' },
      { to: changeEndpoint(pathname, '/absence'), label: 'Absence' },
      { to: changeEndpoint(pathname, '/security'), label: 'Security' },
      { to: changeEndpoint(pathname, '/map'), label: 'Map' },
    ],
    [pathname]
  );

  return (
    <div className={classes.Drawer}>
      <div
        className={classNames(classes.backdrop, { [classes.show]: show })}
        onClick={hideDrawer}
      />

      <div className={classNames(classes.content, { [classes.show]: show })}>
        <nav>
          {options.map((option) => (
            <Link
              key={option.label}
              className={classNames({
                [classes.active]: pathname === option.to,
              })}
              to={option.to}
            >
              {t(option.label)}
            </Link>
          ))}
        </nav>
        <button
          type="button"
          className={classes.logoutButton}
          onClick={handleLogout}
        >
          {t('Log out')}
        </button>
      </div>
    </div>
  );
}
