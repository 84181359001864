/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import classNames from 'classnames';

import deleteIcon from '../../assets/images/delete.svg';
import updateIcon from '../../assets/images/update.svg';
import { API_URL } from '../../constants/main';
import { ROLES } from '../../helpers/authStorage';
import CreateOrUpdateUserModal from '../../modals/CreateOrUpdateUserModal';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import Header from '../../components/Header';
import Spinner from '../../components/UI/Spinner';
import classes from './styles.module.scss';

export default function AdminPanelPage() {
  const [selectedRole, setSelectedRole] = useState(ROLES.STATISTICS_VIEWER);
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModalType, setUserModalType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [
    isCreateOrUpdateUserModalVisible,
    setIsCreateOrUpdateUserModalVisible,
  ] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const getUsers = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${API_URL}/visitorsCounterAdmins`, {
        params: { role: selectedRole },
      });

      setIsLoading(false);
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [selectedRole]);

  useEffect(() => {
    getUsers();
  }, [selectedRole]);

  const createUser = useCallback(
    async ({ login, password }) => {
      try {
        const response = await axios.post(`${API_URL}/visitorsCounterAdmin`, {
          login,
          password,
          role: selectedRole,
        });

        setUsers((prevUsers) => [...prevUsers, response.data]);
      } catch (error) {
        console.log(error);
      }
    },
    [selectedRole]
  );

  const updateUser = useCallback(async (userId, { password }) => {
    try {
      await axios.patch(`${API_URL}/visitorsCounterAdmin/${userId}`, {
        password,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteUser = useCallback(async (userId) => {
    try {
      await axios.delete(`${API_URL}/visitorsCounterAdmin/${userId}`);

      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleCloseDeleteConfirmationModal = () => {
    setSelectedUser(null);
    setIsDeleteConfirmationModalVisible(false);
  };

  const handleCloseCreateOrUpdateUserModal = () => {
    setSelectedUser(null);
    setIsCreateOrUpdateUserModalVisible(false);
  };

  const handleDeleteUser = (user) => {
    setIsDeleteConfirmationModalVisible(true);
    setSelectedUser(user);
  };

  return (
    <div className={classes.AdminPanelPage}>
      <Header>
        <nav className={classes.navigation}>
          <span
            className={classNames(classes.link, {
              [classes.active]: selectedRole === ROLES.STATISTICS_VIEWER,
            })}
            onClick={() => setSelectedRole(ROLES.STATISTICS_VIEWER)}
          >
            Managers
          </span>

          <span
            className={classNames(classes.link, {
              [classes.active]: selectedRole === ROLES.GATE_KEEPER,
            })}
            onClick={() => setSelectedRole(ROLES.GATE_KEEPER)}
          >
            Security
          </span>
        </nav>
      </Header>

      <div className={classes.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Login</th>
              <th>
                <button
                  type="button"
                  className={classes.applyButton}
                  onClick={() => {
                    setUserModalType('create');
                    setIsCreateOrUpdateUserModalVisible(true);
                  }}
                >
                  Add new
                </button>
              </th>
            </tr>
          </thead>
          {isLoading ? (
            <div className={classes.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <tbody>
              {users?.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.login}</td>
                  <td className={classes.buttons}>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedUser(user);
                        setUserModalType('update');
                        setIsCreateOrUpdateUserModalVisible(true);
                      }}
                    >
                      <img src={updateIcon} alt="Update" />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDeleteUser(user)}
                    >
                      <img src={deleteIcon} alt="Delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <CreateOrUpdateUserModal
        show={isCreateOrUpdateUserModalVisible}
        handleClose={handleCloseCreateOrUpdateUserModal}
        handleSubmit={(values) =>
          userModalType === 'create'
            ? createUser(values)
            : updateUser(selectedUser.id, values)
        }
        selectedUser={selectedUser}
        type={userModalType}
      />

      <DeleteConfirmationModal
        show={isDeleteConfirmationModalVisible}
        handleClose={handleCloseDeleteConfirmationModal}
        handleConfirm={() => deleteUser(selectedUser.id)}
      />
    </div>
  );
}
