import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { uniq, without } from 'lodash';
import axios from 'axios';
import { useDebounce } from 'use-debounce';

import { getAuthData, getTokenData } from '../../helpers/authStorage';
import { API_URL } from '../../constants/main';
import Header from '../../components/Header';
import DeclineModal from '../../modals/DeclineModal';
import classes from './styles.module.scss';

const formatUrl = (url) => url.replace(/http:/g, 'https:');

const CheckInPage = ({ match }) => {
  const [recognizedUrls, setRecognizedUrls] = useState([]);
  const [scannedText, setScannedText] = useState('');
  const [debouncedScannedText] = useDebounce(scannedText, 500);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isDeclineError, setIsDeclineError] = useState(false);
  const [declineReason, setDeclineReason] = useState('');

  const { t } = useTranslation();

  const inputRef = useRef();

  const focusInput = useCallback(
    () => setTimeout(() => inputRef.current?.focus(), 100),
    [inputRef.current]
  );

  const getShortLink = (url) => {
    const [, , , shortLink] = url.split('/');
    return shortLink;
  };

  const addUrl = () => {
    setScannedText('');

    if (
      !/https?:\/\//i.test(debouncedScannedText) ||
      /https?:\/\/.*https?:\/\//i.test(debouncedScannedText)
    ) {
      return;
    }

    const { accessToken } = getAuthData();

    setRecognizedUrls(
      uniq([
        ...recognizedUrls,
        formatUrl(`${debouncedScannedText}?accessToken=${accessToken}`),
      ])
    );
  };

  const removeUrl = (url) => setRecognizedUrls(without(recognizedUrls, url));

  const trackCheckIn = (url, isEnter) => {
    const shortLink = getShortLink(url);
    axios
      .post(`${API_URL}/check-ins/${shortLink}`, {
        location: match.params.location.toUpperCase(),
        country: match.params.country.toUpperCase(),
        isEnter,
      })
      .then(() => removeUrl(url));
  };

  const decline = (url) => {
    if (!declineReason.trim()) {
      return;
    }

    const shortLink = getShortLink(url);
    const { id } = getTokenData();

    setIsDeclineError(false);

    axios
      .post(`${API_URL}/declines/${shortLink}`, {
        note: declineReason,
        declinedById: id,
      })
      .catch((error) => {
        setIsDeclineError(true);
        console.log(error);
      })
      .then(() => {
        setIsDeclineModalVisible(false);
        setTimeout(() => {
          removeUrl(url);
          setDeclineReason('');
        }, 200);
      });
  };

  useEffect(focusInput, []);
  useEffect(focusInput, [isDeclineModalVisible]);
  useEffect(addUrl, [recognizedUrls, debouncedScannedText]);

  return (
    <div className={classes.root}>
      <Header>
        <input
          className={classes.linkInput}
          type="text"
          onBlur={isDeclineModalVisible ? undefined : focusInput}
          onChange={(e) => setScannedText(e.target.value)}
          value={scannedText}
          ref={inputRef}
        />
      </Header>

      {recognizedUrls.map((url) => (
        <div className={classes.userPageContainer} key={url}>
          <iframe
            src={url}
            className={classes.preview}
            title={t('Preview')}
            scrolling="auto"
          />

          <div className={classes.controls}>
            <button
              type="button"
              className={classes.confirmEnter}
              onClick={() => trackCheckIn(url, true)}
            >
              {t('Enter')}
            </button>
            <button
              type="button"
              className={classes.confirmExit}
              onClick={() => trackCheckIn(url, false)}
            >
              {t('Exit')}
            </button>
            <button
              type="button"
              className={classes.remove}
              // onClick={() => removeUrl(url)}
              onClick={() => setIsDeclineModalVisible(true)}
            >
              {t('Decline')}
            </button>
          </div>
          <DeclineModal
            show={isDeclineModalVisible}
            isError={isDeclineError}
            handleClose={() => setIsDeclineModalVisible(false)}
            declineReason={declineReason}
            setDeclineReason={setDeclineReason}
            onConfirm={() => decline(url)}
          />
        </div>
      ))}
    </div>
  );
};

export default CheckInPage;

// RESOURCE USAGE EXAMPLE:
// import { connect } from 'react-redux';
//
// import { actions as userActions } from '../../resources/users';
//
// const ExamplePage = ({ users, fetchUsers }) => {
//   useEffect(fetchUsers, []);
//
//   return <div>{isFetching ? <Spinner /> : users.map(({ name }) => name)}</div>;
// };
// export default connect(
//   (state) => ({
//     users: state.users.items,
//     isFetching: state.users.isFetching,
//     pageIndex: state.users.pageIndex,
//     totalItemsCount: state.users.totalItemsCount,
//   }),
//   {
//     fetchUsers: userActions.fetchUsers,
//   }
// )(ExamplePage);
