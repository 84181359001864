/* eslint-disable no-plusplus */
import React, { useState, useCallback, useRef, useEffect } from 'react';

import axios from 'axios';

import { API_URL } from '../../constants/main';
import LogsTable from '../LogsTable';

const pageSize = 10;

const CheckInList = ({
  match,
  type,
  residencyStatus,
  headers,
  filters,
  isListLoading,
  setIsListLoading,
  setIsImageModalVisible,
  setSelectedPhoto,
}) => {
  const [data, setData] = useState([]);

  const count = useRef();
  const page = useRef();
  const isFetched = useRef();
  const observer = useRef();

  const fetchUrl = `${API_URL}/check-ins/${type}/${residencyStatus}/${match.params.country.toUpperCase()}/${match.params.location.toUpperCase()}`;

  // eslint-disable-next-line consistent-return
  const request = async (url, params) => {
    try {
      setIsListLoading(true);

      const response = await axios.get(url, {
        params: { ...params, ...filters },
      });

      setIsListLoading(false);

      if (!isFetched.current) {
        isFetched.current = true;
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = (url, params, reset) => {
    request(url, params).then((responseData) => {
      count.current = responseData.count;
      if (reset) {
        setData(responseData.data);
      } else {
        setData((prevData) => [...prevData, ...responseData.data]);
      }
    });
  };

  useEffect(() => {
    if (!isFetched.current) {
      return;
    }

    page.current = 0;
    count.current = 0;
    fetchData(
      fetchUrl,
      {
        page: page.current,
        pageSize,
      },
      true
    );
  }, [filters]);

  useEffect(() => {
    if (!page.current) {
      page.current = 0;
    }
    if (!count.current) {
      count.current = 0;
    }
    fetchData(fetchUrl, { page: page.current, pageSize });
  }, []);

  const handleUserAvatarClick = useCallback((avatar) => {
    setSelectedPhoto(avatar);
    setIsImageModalVisible(true);
  }, []);

  const lastListElementRef = useCallback(
    (node) => {
      if (isListLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          count.current > (page.current + 1) * pageSize
        ) {
          page.current++;
          fetchData(fetchUrl, { pageSize, page: page.current });
        }
      });
      if (node) observer.current.observe(node);
    },
    [isListLoading, count, page]
  );

  return (
    <LogsTable
      headers={headers}
      data={data}
      lastListElementRef={lastListElementRef}
      onUserAvatarClick={handleUserAvatarClick}
      isLoading={isListLoading}
    />
  );
};

export default CheckInList;
