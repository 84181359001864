/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as yup from 'yup';

import logo from '../../assets/images/logo.png';
import { actions } from '../../resources/users';
import { ROLES } from '../../helpers/authStorage';
import Toast from '../../components/UI/Toast';
import Input from '../../components/UI/Input';
import FilledButton from '../../components/UI/Buttons/FilledButton';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  login: yup.string().trim().required('Login is required'),
  password: yup.string().required('Password is required'),
});

export default function SecurityLoginPage() {
  const [isNotifyVisible, setIsNotifyVisible] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const login = async (values) => {
    try {
      await dispatch(
        actions.loginUser({
          login: values.login,
          password: values.password,
          role: ROLES.GATE_KEEPER,
        })
      );
      history.push('/nl/dwl');
    } catch (error) {
      setIsNotifyVisible(true);
      setTimeout(() => setIsNotifyVisible(false), 5000);
    }
  };

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema,
    onSubmit: login,
  });

  const isSubmitDisabled = useMemo(
    () => !formik.values.login.length || !formik.values.password.length,
    [formik.values.login, formik.values.password]
  );

  return (
    <div className={classes.SecurityLoginPage}>
      <div className={classes.leftSide}>
        <form onSubmit={formik.handleSubmit}>
          <h1>{t('Welcome to')} DWL Gates</h1>
          <Input
            inputType="default"
            value={formik.values.login}
            errors={formik.errors.login}
            isTouched={formik.touched.login}
            label="Login"
            type="text"
            name="login"
            placeholder="Login"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Input
            inputType="password"
            value={formik.values.password}
            errors={formik.errors.password}
            isTouched={formik.touched.password}
            label="Password"
            name="password"
            placeholder={t('Password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FilledButton isDisabled={isSubmitDisabled}>Login</FilledButton>
        </form>
      </div>

      <div className={classes.rightSide}>
        <img src={logo} alt="UQ" />
      </div>

      <Toast show={isNotifyVisible}>
        {t('Something went wrong')}
        <br />
        {t('Please contact with admin')} +31638618185
      </Toast>
    </div>
  );
}
