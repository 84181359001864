/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import CalendarModal from './CalendarModal';
import classes from './styles.module.scss';

export default function CalendarInput({ placeholder, value, setValue }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeRange, setActiveRange] = useState('');

  const hideModal = () => setIsModalVisible(false);

  useEffect(() => {
    if (!value?.length) {
      setActiveRange('');
    }
  }, [value]);

  const selectRange = (range) => {
    const startingDate = new Date();
    const currentDay = startingDate.getDay();

    const deselectRange = () => {
      setValue(null);
      setActiveRange('');
    };

    const toggleThisWeek = () => {
      if (activeRange === 'This week') {
        deselectRange();
        return;
      }

      const diff =
        startingDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);

      setValue([
        new Date(startingDate.setDate(diff)),
        new Date(startingDate.setDate(startingDate.getDate() + 6)),
      ]);
      setActiveRange('This week');
    };

    const toggleLastWeek = () => {
      if (activeRange === 'Last week') {
        deselectRange();
        return;
      }

      const diff =
        startingDate.getDate() - currentDay + (currentDay === 0 ? -13 : -6);

      setValue([
        new Date(startingDate.setDate(diff)),
        new Date(startingDate.setDate(startingDate.getDate() + 6)),
      ]);
      setActiveRange('Last week');
    };

    const toggleThisMonth = () => {
      if (activeRange === 'This month') {
        deselectRange();
        return;
      }

      setValue([
        new Date(
          new Date(startingDate.getFullYear(), startingDate.getMonth(), 1)
        ),
        new Date(startingDate.getFullYear(), startingDate.getMonth() + 1, 0),
      ]);
      setActiveRange('This month');
    };

    const toggleThisYear = () => {
      if (activeRange === 'This year') {
        deselectRange();
        return;
      }

      setValue([
        new Date(startingDate.getFullYear(), 0, 1),
        new Date(startingDate.getFullYear(), 11, 31),
      ]);
      setActiveRange('This year');
    };

    const toggleLastYear = () => {
      if (activeRange === 'Last year') {
        deselectRange();
        return;
      }

      setValue([
        new Date(startingDate.getFullYear() - 1, 0, 1),
        new Date(startingDate.getFullYear() - 1, 11, 31),
      ]);
      setActiveRange('Last year');
    };

    const toggleAllTime = () => {
      if (activeRange === 'All time') {
        deselectRange();
        return;
      }

      const today = new Date();
      const longTimeAgo = new Date(startingDate.setFullYear(1999));
      setValue([
        new Date(longTimeAgo.getFullYear(), 0, 1),
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
      ]);
      setActiveRange('All time');
    };

    switch (true) {
      case range === 'This week':
        toggleThisWeek();
        break;
      case range === 'Last week':
        toggleLastWeek();
        break;
      case range === 'This month':
        toggleThisMonth();
        break;
      case range === 'This year':
        toggleThisYear();
        break;
      case range === 'Last year':
        toggleLastYear();
        break;
      case range === 'All time':
        toggleAllTime();
        break;
      default:
    }
  };

  let label = placeholder;

  if (activeRange) {
    label = activeRange;
  } else if (value) {
    const formattedStartingDate = moment(value[0]).format('DD/MM/YYYY');
    const formattedEndingDate = moment(value[1]).format('DD/MM/YYYY');
    const isDatesEqual = formattedStartingDate === formattedEndingDate;

    if (isDatesEqual) {
      label = formattedStartingDate;
    } else {
      label = `${formattedStartingDate} - ${formattedEndingDate}`;
    }
  }

  return (
    <div className={classes.Calendar}>
      <div
        className={classNames(classes.input, {
          [classes.hasValue]: value,
        })}
        onClick={() => setIsModalVisible(true)}
      >
        {label}
      </div>
      {isModalVisible && (
        <CalendarModal
          activeRange={activeRange}
          setActiveRange={setActiveRange}
          selectRange={selectRange}
          value={value}
          setValue={setValue}
          hideModal={hideModal}
        />
      )}
    </div>
  );
}
