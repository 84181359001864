import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../ModalContainer';
import classes from './styles.module.scss';

export default function DeclineModal({
  show,
  isError,
  handleClose,
  declineReason,
  setDeclineReason,
  onConfirm,
}) {
  const { t } = useTranslation();

  return (
    <ModalContainer show={show} handleClose={handleClose}>
      <div className={classes.DeclineModal}>
        <h1>
          {t('Describe this case')} <br />
          {t('Including name tel number room number person')}
        </h1>
        <textarea
          value={declineReason}
          onChange={(event) => setDeclineReason(event.target.value)}
        />
        {!!isError && <div className={classes.error}>Error!</div>}
        <button type="button" onClick={onConfirm}>
          Ok
        </button>
      </div>
    </ModalContainer>
  );
}
