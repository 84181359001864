/* eslint-disable prefer-destructuring */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { formatDateByLanguage } from '../../helpers/formatDateByLanguage';
import { API_URL } from '../../constants/main';
import useResizeObserver from '../../hooks/useResizeObserver';
import ImageModal from '../../modals/ImageModal';
import Filters from '../../components/Filters';
import Header from '../../components/Header';
import CheckInList from '../../components/CheckInList';
import classes from './styles.module.scss';
import { adjustTimezoneOffset } from '../../helpers/adjustTimezoneOffset';

const exportAsPdf = (enters, exits, location) => {
  /* eslint-disable new-cap */
  const doc = new jsPDF();
  const head = [['Date', 'Time', 'Name', 'Last name', 'Room number', 'Age']];
  const x = doc.internal.pageSize.width / 2;
  const docOptions = {
    startY: 28,
    headStyles: { fillColor: '#007aff' },
    head,
    theme: 'grid',
    styles: {
      halign: 'center',
    },
  };
  const formattedDate = moment().format('DD-MM-YYYY HH:mm');

  doc.setFontSize(24);
  doc.setTextColor('#007aff');

  if (enters.length) {
    doc.text(`ENTRANCE \n ${location} ${formattedDate}`, x, 12, {
      align: 'center',
    });

    autoTable(doc, {
      ...docOptions,
      body: enters,
    });

    if (exits.length) {
      doc.addPage();
    }
  }

  if (exits.length) {
    doc.text(`EXIT \n ${location} ${formattedDate}`, x, 12, {
      align: 'center',
    });

    autoTable(doc, {
      ...docOptions,
      body: exits,
    });
  }

  doc.save(`residents-${location}-${formattedDate}.pdf`);
};

const LogsPage = ({ match }) => {
  const [exitTableWidth, setExitTableWidth] = useState(0);
  const [dateFilter, setDateFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');
  const [roomNumberFilter, setRoomNumberFilter] = useState('');
  const [ageFilter, setAgeFilter] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [checkInsCount, setCheckInsCount] = useState({ outside: 0, inside: 0 });
  const [timeFromFilter, setTimeFromFilter] = useState(null);
  const [timeToFilter, setTimeToFilter] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [isListLoading, setIsListLoading] = useState(true);

  const { t } = useTranslation();

  const textFilters = [
    {
      placeholder: t('Name'),
      value: nameFilter,
      setValue: setNameFilter,
      name: 'name',
    },
    {
      placeholder: t('Last name'),
      value: lastNameFilter,
      setValue: setLastNameFilter,
      name: 'lastName',
    },
    {
      placeholder: t('Room number'),
      value: roomNumberFilter,
      setValue: setRoomNumberFilter,
      name: 'roomNumber',
    },
  ];

  const selectFilters = [
    {
      width: 108,
      placeholder: t('Age'),
      value: ageFilter,
      setValue: setAgeFilter,
      name: 'age',
      options: [
        { label: '0-7', value: [0, 7] },
        { label: '8-13', value: [8, 13] },
        { label: '14-18', value: [14, 18] },
        { label: '19-60', value: [19, 60] },
        { label: '61+', value: [61, '+'] },
      ],
    },
  ];

  const calendarFilters = [
    {
      placeholder: t('Date'),
      value: dateFilter,
      setValue: setDateFilter,
      name: 'date',
    },
  ];

  const timeRangeFilters = [
    {
      placeholderFrom: t('From'),
      placeholderTo: t('To'),
      name: 'time',
      fromValue: timeFromFilter,
      setFromValue: setTimeFromFilter,
      toValue: timeToFilter,
      setToValue: setTimeToFilter,
    },
  ];

  const applyFilters = (reset) => {
    const filters = {};

    if (reset === true) {
      setAppliedFilters(filters);
      return;
    }

    textFilters.forEach((filter) => {
      filters[filter.name] = filter.value;
    });

    selectFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(
        filter.value.map((val) => val.value)
      );
    });

    calendarFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(filter.value);
    });

    timeRangeFilters.forEach((filter) => {
      filters[filter.name] = JSON.stringify(
        adjustTimezoneOffset(filter.fromValue, filter.toValue)
      );
    });

    setAppliedFilters(filters);
  };

  const entranceTableContainerRef = useRef();
  const { width: entranceTableWidth } = useResizeObserver(
    entranceTableContainerRef
  );

  const exitTableContainerRef = useRef();
  const { width: exitTableWidthWithoutScrollbar } = useResizeObserver(
    exitTableContainerRef
  );

  useEffect(() => {
    setExitTableWidth(exitTableContainerRef.current.offsetWidth);
  }, [exitTableWidthWithoutScrollbar]);

  useEffect(() => {
    const getCheckInsCount = async () => {
      try {
        const response = await axios.get(`${API_URL}/check-ins/count/resident`);

        setCheckInsCount(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCheckInsCount();
  }, []);

  // eslint-disable-next-line consistent-return
  const getCheckInsForExport = async (type) => {
    const fetchUrl = `${API_URL}/check-ins/${type}/resident/${match.params.country.toUpperCase()}/${match.params.location.toUpperCase()}`;

    try {
      const response = await axios.get(fetchUrl, {
        params: {
          ...appliedFilters,
        },
      });

      const formattedData = response.data.data.map((checkIn) => {
        const date = formatDateByLanguage(checkIn.createdAt);
        const time = moment(checkIn.createdAt).format('HH:mm');
        const name = (!!checkIn.page && checkIn.page.firstName) || '';
        const lastName = (!!checkIn.page && checkIn.page.lastName) || '';
        const roomNumber = (!!checkIn.page && checkIn.page.roomNumber) || '';
        const age =
          (!!checkIn.page &&
            !!checkIn.page.birthday &&
            moment().diff(
              moment(checkIn.page.birthday, 'YYYY-MM-DD'),
              'years'
            )) ||
          '';

        return [date, time, name, lastName, roomNumber, age];
      });

      return formattedData;
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    try {
      const entersPromise = getCheckInsForExport('enters');
      const exitsPromise = getCheckInsForExport('exits');

      const [enters, exits] = await Promise.all([entersPromise, exitsPromise]);

      if (enters.length || exits.length) {
        exportAsPdf(enters, exits, match.params.location.toUpperCase());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      <Header className={classes.header} logoClassName={classes.logo}>
        <div className={classes.pageTitle}>{t('Entrance/Exit')}</div>
      </Header>
      <Filters
        textFilters={textFilters}
        selectFilters={selectFilters}
        calendarFilters={calendarFilters}
        timeRangeFilters={timeRangeFilters}
        applyFilters={applyFilters}
        handleExport={handleExport}
      />

      <div className={classes.tables}>
        <div className={classes.enters} ref={entranceTableContainerRef}>
          <div className={classes.title}>{t('Entrance')}</div>

          <CheckInList
            type="enters"
            residencyStatus="resident"
            headers={[
              'Date',
              'Time',
              'Photo',
              'Name',
              'Last name',
              'Room number',
              'Age',
            ]}
            match={match}
            filters={appliedFilters}
            isListLoading={isListLoading}
            setIsListLoading={setIsListLoading}
            setIsImageModalVisible={setIsImageModalVisible}
            setSelectedPhoto={setSelectedPhoto}
          />
        </div>

        <div className={classes.exits} ref={exitTableContainerRef}>
          <div className={classes.title}>{t('Exit')}</div>
          <CheckInList
            type="exits"
            residencyStatus="resident"
            headers={[
              'Date',
              'Time',
              'Photo',
              'Name',
              'Last name',
              'Room number',
              'Age',
            ]}
            match={match}
            filters={appliedFilters}
            isListLoading={isListLoading}
            setIsListLoading={setIsListLoading}
            setIsImageModalVisible={setIsImageModalVisible}
            setSelectedPhoto={setSelectedPhoto}
          />
        </div>
      </div>
      <div className={classes.counters}>
        <div
          className={classes.inside}
          style={{ maxWidth: entranceTableWidth }}
        >
          <span>
            {t('Inside')}: {checkInsCount.inside}
          </span>
        </div>
        <div className={classes.outside} style={{ maxWidth: exitTableWidth }}>
          <span>
            {t('Outside')}: {checkInsCount.outside}
          </span>
        </div>
      </div>

      <ImageModal
        show={isImageModalVisible}
        handleClose={() => setIsImageModalVisible(false)}
        image={selectedPhoto}
      />
    </div>
  );
};

export default LogsPage;
