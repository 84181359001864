import React from 'react';

import axios from 'axios';
import classNames from 'classnames';

import { API_URL } from '../../../constants/main';
import classes from './styles.module.scss';

export default function ConfirmationCheckbox({
  isConfirmed,
  declineId,
  setDeclines,
}) {
  const toggleDeclineConfirmation = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/declines/${declineId}/toggle-confirmation`
      );

      setDeclines((prevDeclines) =>
        prevDeclines.map((decline) => {
          if (decline.id === response.data.id) {
            return response.data;
          }
          return decline;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      onClick={toggleDeclineConfirmation}
      className={classNames(classes.ConfirmationCheckbox, {
        [classes.selected]: isConfirmed,
      })}
    />
  );
}
