/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as yup from 'yup';

import logo from '../../assets/images/logo.png';
import { ROLES } from '../../helpers/authStorage';
import { actions } from '../../resources/users';
import Input from '../../components/UI/Input';
import FilledButton from '../../components/UI/Buttons/FilledButton';
import Toast from '../../components/UI/Toast';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  login: yup.string().trim().required('Login is required'),
  password: yup.string().required('Password is required'),
});

export default function ManagerLoginPage() {
  const [isNotifyVisible, setIsNotifyVisible] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const login = async (values) => {
    try {
      await dispatch(
        actions.loginUser({
          login: values.login,
          password: values.password,
          role: ROLES.STATISTICS_VIEWER,
        })
      );
      history.push('/nl/dwl/logs');
    } catch (error) {
      setIsNotifyVisible(true);
      setTimeout(() => setIsNotifyVisible(false), 5000);
    }
  };

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema,
    onSubmit: login,
  });

  const isSubmitDisabled = useMemo(
    () => !formik.values.login.length || !formik.values.password.length,
    [formik.values.login, formik.values.password]
  );

  return (
    <div className={classes.ManagerLoginPage}>
      <img className={classes.uqLogo} src={logo} alt="UQ" />
      <form onSubmit={formik.handleSubmit}>
        <h1>{t("Welcome to UQ Manager's dashboard location")} DWL</h1>
        <Input
          inputType="default"
          value={formik.values.login}
          errors={formik.errors.login}
          isTouched={formik.touched.login}
          type="text"
          name="login"
          placeholder="Login"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Input
          inputType="password"
          value={formik.values.password}
          errors={formik.errors.password}
          isTouched={formik.touched.password}
          name="password"
          placeholder={t('Password')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <FilledButton isDisabled={isSubmitDisabled}>Login</FilledButton>
      </form>

      <Toast show={isNotifyVisible}>
        {t('Something went wrong')}
        <br />
        {t('Please contact with admin')} +31638618185
      </Toast>
    </div>
  );
}
