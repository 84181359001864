import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getAuthData, hasAuthData } from '../../helpers/authStorage';

const PrivateRoute = ({
  component: Component,
  role,
  redirectPath,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      hasAuthData() && getAuthData().role === role ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
