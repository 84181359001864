import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../ModalContainer';
import classes from './styles.module.scss';

export default function DeleteConfirmationModal({
  show,
  handleClose,
  handleConfirm,
}) {
  const { t } = useTranslation();

  return (
    <ModalContainer show={show} handleClose={handleClose}>
      <div className={classes.DeleteConfirmationModal}>
        <h1>{t('Are you sure you want to delete?')}</h1>
        <div className={classes.buttonsContainer}>
          <button
            type="button"
            className={classes.cancelButton}
            onClick={handleClose}
          >
            {t('Cancel')}
          </button>

          <button
            type="button"
            className={classes.deleteButton}
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            {t('Delete')}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
}
